import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { Sheet, Table } from '@mui/joy';
import { useMemo } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import Spinner from '../../components/dynamicSpinner';
import NoData from '../../components/noData';
import { useInsightBalanceDrilldown } from '../../hooks/insights/useInsightBalanceDrilldown';
import { AmountIndicator } from '../completeness-drilldown/table';
import { balanceCheckIconOptions } from '../insights/modal/balanceCheckSubForm';

type InsightDrilldownBalanceTableProps = {
  division: string;
  yearStart: number;
  yearEnd: number;
  monthStart: number;
  monthEnd: number;
  showBalance: boolean;
};

export const YearIcon = (props: { value: boolean }) => {
  const { value } = props;
  switch (value) {
    case true:
      return <CheckCircleIcon className="w-5 text-green-500" />;
    default:
      return <XCircleIcon className="w-5 text-yellow-500" />;
  }
};

export const BalanceInsightTable = ({ division, monthEnd, monthStart, yearEnd, yearStart, showBalance }: InsightDrilldownBalanceTableProps) => {
  const drilldownQuery = useInsightBalanceDrilldown(division, {
    monthEnd: monthEnd + 1,
    monthStart: monthStart + 1,
    yearEnd,
    yearStart,
  });

  const rows = useMemo(() => {
    if (!drilldownQuery.isSuccess) {
      return [];
    }
    const data = drilldownQuery.data;
    if (!data) {
      return [];
    }
    return data.rows;
  }, [drilldownQuery.data]);

  if (drilldownQuery.isLoading) {
    return <Spinner />;
  }

  if (!drilldownQuery.data?.rows.length) {
    return <NoData />;
  }

  if (!drilldownQuery.isSuccess) {
    return <NoData label="Er ging iets mis met de data ophalen" />;
  }

  return (
    <>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          'width': '100%',
          'borderRadius': 'sm',
          'flexShrink': 1,
          'overflow': 'auto',
          'minHeight': 0,
          'maxHeight': '60vh',
          '--TableCell-height': '3rem',
          '& tr': {
            '&:hover': {
              cursor: 'pointer',
            },
          },
        }}
      >
        <Table aria-label="basic table" stickyHeader stickyFooter hoverRow>
          <thead>
            <tr>
              <th>Label</th>
              <th>RGS code</th>
              {drilldownQuery.data.range.map((yearMonth) => {
                // Get month label in dutch
                const month = new Date(yearMonth.year, yearMonth.month - 1).toLocaleString('nl-NL', { month: 'short' });
                return (
                  <th
                    style={{
                      width: `${70 / drilldownQuery.data.range.length}%`,
                    }}
                  >
                    {month}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => {
              return (
                <tr key={row.label}>
                  <td>{row.label}</td>
                  <td>{row.rgsCode}</td>
                  {drilldownQuery.data.range.map((yearMonth) => {
                    const data = row.triggers[yearMonth.year][yearMonth.month];

                    if (showBalance) {
                      return (
                        <td>
                          <AmountIndicator outlier={data.triggered} value={data.balance} disabled={false} />
                        </td>
                      );
                    }

                    // Disabled if in the future
                    const now = new Date();
                    now.setFullYear(yearMonth.year);
                    now.setMonth(yearMonth.month - 1);

                    const disabled = now > new Date();

                    const iconData = balanceCheckIconOptions.find((item) => item.id === row.icon);

                    return (
                      <td>{iconData && data.triggered ? <iconData.component sx={{ color: iconData.color }} /> : <YearIcon value={!data.triggered} />}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
    </>
  );
};
