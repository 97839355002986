import { ArrowSmallDownIcon, ArrowUpIcon, ExclamationTriangleIcon, HandThumbUpIcon, MinusIcon, XCircleIcon } from '@heroicons/react/24/solid';
import DirectionsCarFilledRoundedIcon from '@mui/icons-material/DirectionsCarFilledRounded';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import { Pagination } from '@mui/material';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NoData from '../../components/noData';
import Spinner from '../../components/spinner';
import useKiaAdvice from '../../hooks/useKiaAdvice';
import { formatter } from '../../translations/numberFormatter';

const PAGE_SIZE = 15;

type StatusIndicatorProps = {
  tier: number;
};

const StatusIndicator = ({ tier }: StatusIndicatorProps) => {
  const styles = 'w-5 opacity-50';
  switch (tier) {
    case 1:
      return <MinusIcon className={clsx(styles, 'text-grey-500')} />;
    case 2:
      return <ArrowUpIcon className={clsx(styles, 'text-green-500')} />;
    case 3:
      return <HandThumbUpIcon className={clsx(styles, 'text-green-500')} />;
    case 4:
      return <ArrowSmallDownIcon className={clsx(styles, 'text-orange-500')} />;
    case 5:
      return <XCircleIcon className={clsx(styles, 'text-red-500')} />;
    default:
      return <ExclamationTriangleIcon className={clsx(styles, 'text-orange-500')} />;
  }
};

type ProgressBarProps = {
  tiers: number;
  tier: number;
};
const ProgressBar = (props: ProgressBarProps) => {
  const { tier, tiers } = props;

  const colors = ['bg-red-500', 'bg-green-300', 'bg-green-500', 'bg-yellow-300', 'bg-red-500'];

  return (
    <div className={clsx('px-3 grid h-3 w-full grid-cols-5 items-center gap-1 rounded-full border-fuchsia-700')}>
      {new Array(tiers).fill(null).map((_, index) => {
        return (
          <>
            <Box
              sx={{ backgroundColor: 'background.level1' }}
              className={clsx('relative flex h-2 items-center ', index === 0 && 'rounded-l-full', index === tiers - 1 && 'rounded-r-full')}
              key={`full_${index}`}
            >
              {index < tier && (
                <div
                  className={clsx(
                    'relative h-3 w-full bg-red-500 bg-opacity-60',
                    colors[tier - 1],
                    index === tier - 1 && 'w-3/5',
                    index === 0 && 'rounded-l-full',
                  )}
                  key={`half_${index}`}
                />
              )}
            </Box>
          </>
        );
      })}
    </div>
  );
};

type Props = {
  divisionSearch: string;
  year: number;
  userId: string | undefined;
};

const AdviceTable = (props: Props) => {
  const { year, divisionSearch, userId } = props;
  const kiaQuery = useKiaAdvice(year, userId);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const onRowClick = (id: string) => {
    navigate(`/administrations/${id}/advice`);
  };

  if (!kiaQuery.isSuccess) {
    return <Spinner />;
  }

  const rows = kiaQuery.data.filter((row) => {
    return row.name.toLowerCase().includes(divisionSearch);
  });

  if (!rows.length) {
    return <NoData />;
  }

  return (
    <>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
          maxHeight: '60vh',
        }}
      >
        <Table
          aria-label="basic table"
          stickyHeader
          hoverRow
          stickyFooter
          sx={{
            '& tr': {
              '&:hover': {
                cursor: 'pointer',
              },
            },
          }}
        >
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Omschrijving</th>
              <th style={{ width: '6rem' }}>Investering</th>
              <th style={{ width: '6rem' }}>Aftrek</th>
              <th style={{ width: '3rem' }}></th>
              <th style={{ width: '15%' }}>Huidige trap</th>
              <th>Advies</th>
              <th style={{ width: '3rem' }}></th>
            </tr>
          </thead>
          <tbody>
            {rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((row) => (
              <tr
                key={row.division}
                onClick={() => {
                  onRowClick(row.division as any as string);
                }}
              >
                <td>{row.name.length > 24 ? `${row.name.slice(0, 24)}...` : row.name}</td>
                <td>{formatter.format(Math.round(row.totalInvestment * 100) / 100).slice(0, -3)}</td>
                <td>{formatter.format(Math.round(row.rebate * 100) / 100).slice(0, -3)}</td>
                <td>{row.containsCar && <DirectionsCarFilledRoundedIcon />}</td>
                <td>
                  <ProgressBar tier={row.tier} tiers={5} />
                </td>
                <td>
                  {(() => {
                    const adviceText = row.advice;
                    const match = adviceText.match(/(.*)€([0-9,.]+)(.*)/);
                    if (match && match[2]) {
                      const numberPart = parseFloat(match[2].replace(/,/g, ''));
                      const formattedNumber = formatter.format(numberPart).slice(0, -3);
                      return `${match[1]}${formattedNumber}${match[3]}`;
                    }
                    return row.advice;
                  })()}
                </td>
                <td>
                  <StatusIndicator tier={row.tier} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination count={Math.ceil(rows.length / PAGE_SIZE)} color="primary" onChange={(_, page) => setPage(page - 1)} page={page + 1} />
      </Box>
    </>
  );
};

export default AdviceTable;
