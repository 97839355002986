import client from '../../utils/axiosClient';

export type TQualityDrilldown = {
  range: Array<{ year: number; month: number }>;
  rows: {
    qualityCheckId: string;
    rgsCodes: string;
    complete: boolean;
    yearTotals: {
      [year: number]: {
        transactionTotal: number;
        invoiceTotal: number;
      };
    };
    rows: {
      [year: number]: {
        [month: number]: {
          financialYear: number;
          financialPeriod: number;
          periodTotal: number;
          transactionCount: number;
          automatedCount: number;
          outlier: boolean;
          invoiceOutlier: boolean;
          invoiceCount: number;
        };
      };
    };
  }[];
};

const getCompletenessByDivision = async (divisionCode: string, yearStart: number, yearEnd: number, monthStart: number, monthEnd: number) => {
  const response = await client.get<TQualityDrilldown>(`/api/completeness/${divisionCode}`, {
    params: {
      yearStart,
      yearEnd,
      monthStart,
      monthEnd,
    },
  });
  return response.data;
};

export default getCompletenessByDivision;
