import { useQuery } from "@tanstack/react-query";
import { AdviceType } from "../types/types";
import queryTypes from "../types/hookTypes";
import getKiaAdvice from "../services/api/getKiaAdvice";

const useHook = (year: number, userId?: string | undefined) => {
  return useQuery<Array<AdviceType>>(
    [queryTypes.KiaAdvice, year, userId],
    async () => {
      const result = await getKiaAdvice(year, userId);
      return result;
    }
  );
};

export default useHook;
