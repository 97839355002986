import { Card, Chip, Grid, Stack, Typography } from '@mui/joy';
import { useTheme } from '@mui/joy/styles';
import { format } from 'date-fns';
import { Bar, BarChart, CartesianGrid, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import pieChartData from './pieChartData.json';
import { webhookDummyData } from './webhookdummydata';

const DEFAULT_HEIGHT = 320;

export const IntegrationDashboardExact = () => {
  const theme = useTheme(); // Access Joy UI theme

  const average = Math.round(webhookDummyData.reduce((acc, curr) => acc + curr.divisionWebhooks, 0) / webhookDummyData.length);
  const today = webhookDummyData[webhookDummyData.length - 1].divisionWebhooks;

  const COLORS = [theme.palette.warning[500], theme.palette.success[500], theme.palette.danger[500]];

  return (
    <Stack gap={5}>
      <Typography level="h1">Integration Dashboard Exact</Typography>
      <Grid container spacing={3}>
        {/* BAR CHART */}
        <Grid xs={12} lg={8} height={DEFAULT_HEIGHT}>
          <Card sx={{ height: '100%' }}>
            <Stack gap={3} height={'100%'}>
              <Stack gap={1}>
                <Stack direction={'row'} spacing={1}>
                  <Typography level="h4">Unieke divisies met aangeroepen webhook</Typography>
                  <Chip color="success">Vandaag: {today}</Chip>
                </Stack>
                <Typography level="title-sm">Gemiddeld: {average}</Typography>
              </Stack>
              <ResponsiveContainer width="100%" height={'100%'}>
                <BarChart data={webhookDummyData}>
                  <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />
                  <XAxis
                    dataKey="EventDate"
                    tickFormatter={(dayGroup) => {
                      return format(new Date(dayGroup), 'EEE'); // Format date with abbreviation (Mon, Sep 2)
                    }}
                    stroke={theme.palette.text.primary}
                  />
                  <YAxis stroke={theme.palette.text.primary} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="divisionWebhooks" fill={theme.palette.primary.solidBg} radius={[4, 4, 0, 0]} />
                </BarChart>
              </ResponsiveContainer>
            </Stack>
          </Card>
        </Grid>

        {/* PIE CHART */}
        <Grid xs={12} lg={4} height={DEFAULT_HEIGHT}>
          <Card sx={{ height: '100%' }}>
            <Typography level="h4">Taken status</Typography>
            <ResponsiveContainer width="100%" height={'100%'}>
              <PieChart>
                <Pie
                  data={pieChartData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  fill="#8884d8"
                  dataKey="recordCount"
                  label={(entry) => `${entry.name} (${entry.value})`}
                >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                  formatter={(value) => {
                    let label = `Geen label ${value}`;
                    switch (value) {
                      case 0:
                        label = 'Open';
                        break;
                      case 1:
                        label = 'Afgehandeld';
                        break;
                      case 2:
                        label = 'Anders';
                        break;
                    }
                    return label;
                  }}
                />
              </PieChart>
            </ResponsiveContainer>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};
