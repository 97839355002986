import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Spinner from "../../components/spinner";
import { useInvalidVatTransactions } from "../../hooks/transactions/useInvalidVatTransactions";
import { formatter } from "../../translations/numberFormatter";
import { Typography } from "@mui/joy";

type TInvalidVatTransactionTableProps = {
  administrationId: string;
  monthStart: number;
  monthEnd: number;
  yearStart: number;
  yearEnd: number;
};

export const InvalidVatTransactionTable = (
  props: TInvalidVatTransactionTableProps
) => {
  const { administrationId, monthStart, yearStart, yearEnd, monthEnd } = props;

  const invalidVatTransactionsQuery = useInvalidVatTransactions({
    yearStart,
    yearEnd,
    monthStart,
    monthEnd,
    administrationId,
  });

  if (!invalidVatTransactionsQuery.isSuccess) {
    return <Spinner />;
  }

  const rows = invalidVatTransactionsQuery.data;

  const columns: GridColDef[] = [
    {
      field: "transactionDate",
      headerName: "Datum",
      flex: 0.75,
      valueFormatter(params) {
        const dutchDate = new Date(params.value as string).toLocaleDateString(
          "nl-NL"
        );
        return dutchDate;
      },
      disableColumnMenu: true,
    },
    {
      field: "glAccountCode",
      headerName: "Grootboek nr",
      flex: 0.75,
      disableColumnMenu: true,
    },
    {
      field: "glAccountDescription",
      headerName: "Grootboek beschrijving",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "rgsL5",
      headerName: "RGS code",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "description",
      headerName: "Transactie beschrijving",
      flex: 1.5,
      disableColumnMenu: true,
    },
    {
      field: "transactionAmount",
      headerName: "Transactie bedrag",
      flex: 1,
      disableColumnMenu: true,
      valueFormatter(params) {
        return formatter.format(params.value as number);
      },
    },
    {
      field: "amountVAT",
      headerName: "BTW bedrag",
      flex: 1,
      disableColumnMenu: true,
      valueFormatter(params) {
        return formatter.format(params.value as number);
      },
      renderCell(params) {
        return (
          <Typography color={"warning"} level={"body-sm"}>
            {formatter.format(params.value as number)}
          </Typography>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 12 },
        },
        sorting: {
          sortModel: [
            {
              field: "glAccountCodeCount",
              sort: "desc",
            },
          ],
        },
      }}
      getRowId={(row) => row.transactionId}
    />
  );
};
