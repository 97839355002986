import { Button, CircularProgress, Stack, Textarea } from "@mui/joy";
import { useState } from "react";
import { TCreateNote, createNote } from "../../services/api/notes/createNote";

export const NoteForm = (props: {
  administrationId: string;
  financialPeriod: number;
  financialYear: number;
  rgsCode: string;
  goBack: () => void;
}) => {
  const { administrationId, financialYear, financialPeriod, rgsCode, goBack } =
    props;
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const newNote: TCreateNote = {
        administrationId,
        financialPeriod,
        financialYear,
        rgsCode,
        text: note,
      };
      await createNote(newNote);
      goBack();
    } catch (e: any) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="column" spacing={3}>
        <Textarea
          name="Note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          required
          minRows={5}
          placeholder="Schrijf hier je notitie..."
        />
        <Stack direction="row" spacing={2}>
          <Button
            type="submit"
            disabled={loading || !note}
            endDecorator={loading ? <CircularProgress size="sm" /> : null}
          >
            Opslaan
          </Button>
          <Button type="button" onClick={goBack} variant="outlined">
            Cancel
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
