import client from "../../utils/axiosClient";

const getUsers = async (year: number, userId?: string) => {
  const response = await client.get("/api/kia", {
    params: {
      year,
      userId,
    },
  });
  return response.data;
};

export default getUsers;
