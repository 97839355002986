import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Box, Sheet, Stack, Table } from '@mui/joy';
import { Pagination, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Spinner from '../../../../components/spinner';
import useGLAccountEfficiency from '../../../../hooks/useGLAccountEfficiency';
import { useMemo, useState } from 'react';
import useFilterStore from '../../../../stores/filter';

const PAGE_SIZE = 15;

const EfficiencyTable = () => {
  const [page, setPage] = useState(0);
  const { account } = useFilterStore();
  const efficiencyQuery = useGLAccountEfficiency({
    accountID: account,
  });

  if (!efficiencyQuery.isSuccess) {
    return <Spinner />;
  }

  const rows = efficiencyQuery.data.nonRgsGLAccounts;
  if (!rows.length) {
    return (
      <Stack alignItems={'center'} direction={'column'} sx={{ my: '8rem' }} gap={1}>
        <CheckCircleRoundedIcon className="w-32 h-32 text-success text-center" />
        <Typography>Alle grootboekrekeningen zijn gekoppeld!</Typography>
      </Stack>
    );
  }
  return (
    <>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          'width': '100%',
          'borderRadius': 'sm',
          'flexShrink': 1,
          'overflow': 'auto',
          'minHeight': 0,
          'maxHeight': '60vh',
          '& tr': {
            '&:hover': {
              cursor: 'pointer',
            },
          },
        }}
      >
        <Table aria-label="basic table" stickyHeader stickyFooter hoverRow>
          <thead>
            <tr>
              <th style={{ width: '3rem' }}>Administratienaam</th>
              <th style={{ width: '3rem' }}>Account</th>
              <th style={{ width: '3rem' }}>Grootboekrekening</th>
              <th style={{ width: '3rem' }}>Grootboekrekeningnummer</th>
              <th style={{ width: '3rem' }}>Hid</th>
            </tr>
          </thead>
          <tbody>
            {rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((row) => (
              <tr key={row.GLAccountCode}>
                <td>{row.DivisionDescription}</td>
                <td>{row.accountName}</td>
                <td>{row.GLAccountDescription}</td>
                <td>{row.GLAccountCode}</td>
                <td>{row.Hid}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination count={Math.ceil(rows.length / PAGE_SIZE)} color="primary" onChange={(_, page) => setPage(page - 1)} page={page + 1} />
      </Box>
    </>
  );
};

export default EfficiencyTable;
