import CloudOffRoundedIcon from "@mui/icons-material/CloudOffRounded";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";

export default function NoData({ label }: { label?: string }) {
  return (
    <Box
      sx={{
        display: "flex", // Enable flexbox
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        height: "100%", // Take up full container height
        width: "100%", // Take up full container width
        color: "text.secondary", // Use secondary text color
      }}
    >
      <Stack direction={"column"} gap={1}>
        <CloudOffRoundedIcon
          sx={{
            fontSize: "3rem", // Increase icon size; adjust as needed
            margin: "0 auto", // Center horizontally
          }}
        />
        <Typography sx={{ color: "text.secondary" }} level="h3">
          {label ? label : "No data available"}
        </Typography>
      </Stack>
    </Box>
  );
}
