import { ClerkProvider, RedirectToSignIn, SignedIn, SignedOut } from '@clerk/clerk-react';
import { nlNL } from '@clerk/localizations';
import '@fontsource/inter';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  THEME_ID as MATERIAL_THEME_ID,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
} from '@mui/material/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { nl } from 'date-fns/locale';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { useEffect, useState } from 'react';
import 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from './components/layout';
import Spinner from './components/spinner';
import { AdministrationDetails } from './pages/administration';
import Administrations from './pages/administrations';
import ExactData from './pages/administrations-exact-data';
import CompletenessDrilldown from './pages/completeness-drilldown';
import Completeness from './pages/completeness/index';
import { OpenAPIDocumentation } from './pages/docs';
import { Efficiency } from './pages/efficiency';
import { EfficiencyDrilldown } from './pages/efficiency-drilldown';
import FinancialDashboard from './pages/financial-dashboard';
import Home from './pages/home';
import Insights from './pages/insights';
import InsightsDrilldown from './pages/insights-drilldown';
import Kia from './pages/kia';
import KiaTransactions from './pages/kia-transactions';
import Profile from './pages/profile';
import ReceivablesDashboard from './pages/receivables-dashboard';
import { UserPage } from './pages/users';
import useDomainStore from './stores/domain';
import { colorSchemes } from './theme';
import './translations/i18n';
import { clerk } from './utils/clerk';
import queryClient from './utils/queryClient';
import { InvalidVatDashboard } from './pages/vat';
import { InvalidVatDrilldown } from './pages/vat-drilldown';
import { UbtEmbed } from './pages/ubt';
import { IntegrationDashboardExact } from './pages/integration-dashboard-eol';

const materialTheme = materialExtendTheme({ colorSchemes: colorSchemes });

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}

setDefaultOptions({
  locale: nl,
});

const SubPriveRoute = () => {
  const [loading, setLoading] = useState(true);
  const domainStore = useDomainStore();

  const initializePortalClient = async () => {
    if (domainStore.isInitialized) return;
    setLoading(true);
    await domainStore.init();
    setLoading(false);
  };

  useEffect(() => {
    initializePortalClient();
  }, []);

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center bg-background relative">
        <div className="absolute top-[100px]">
          <Spinner />
        </div>
      </div>
    );
  }

  if (!domainStore.domain) {
    return (
      <div className="w-screen h-screen flex items-center justify-center bg-background relative">
        <div className="absolute top-[100px]">
          <p>Je bent nog niet toegevoegd aan een domein</p>
        </div>
      </div>
    );
  }

  return <Outlet />;
};

const PrivateRoute = () => {
  const [loading, setLoading] = useState(true);

  const load = async () => {
    await clerk.load();
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    load();
  }, []);

  if (loading) {
    return (
      <div className="w-screen h-screen flex items-center justify-center bg-background relative">
        <div className="absolute top-[100px]">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <CssVarsProvider disableTransitionOnChange theme={extendTheme({ colorSchemes })}>
        <CssBaseline enableColorScheme />
        <ClerkProvider localization={nlNL} publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY!} Clerk={clerk}>
          <Toaster position="bottom-center" reverseOrder={false} />
          <SignedIn>
            <QueryClientProvider client={queryClient}>
              <Layout>
                <SubPriveRoute />
              </Layout>
            </QueryClientProvider>
          </SignedIn>
          <SignedOut>
            <RedirectToSignIn />
          </SignedOut>
        </ClerkProvider>
      </CssVarsProvider>
    </MaterialCssVarsProvider>
  );
};

const router = createBrowserRouter([
  {
    path: '/docs',
    element: <OpenAPIDocumentation />,
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        path: '/',
        element: <Home />,
      },

      {
        path: '/profile',
        element: <Profile />,
      },
      {
        path: '/kia',
        element: <Kia />,
      },
      {
        path: '/efficiency/:tab',
        element: <Efficiency />,
      },
      {
        path: '/efficiency/consistency/:glaccountcode',
        element: <EfficiencyDrilldown />,
      },
      {
        path: '/insights',
        element: <Insights />,
      },
      {
        path: '/insights/:divisionId',
        element: <InsightsDrilldown />,
      },
      {
        path: '/kia/transactions/:divisionId',
        element: <KiaTransactions />,
      },
      {
        path: '/financial-dashboard',
        element: <FinancialDashboard />,
      },
      {
        path: '/completeness',
        element: <Completeness />,
      },
      {
        path: '/ubt',
        element: <UbtEmbed />,
      },
      {
        path: '/receivables',
        element: <ReceivablesDashboard />,
      },
      {
        path: '/dashboards/vat',
        element: <InvalidVatDashboard />,
      },
      {
        path: '/dashboards/vat/:administrationId',
        element: <InvalidVatDrilldown />,
      },
      {
        path: '/completeness/:divisionId',
        element: <CompletenessDrilldown />,
      },
      {
        path: '/organization',
        element: (
          <div className="w-full h-full flex justify-center items-center -mt-32">
            <p>🚧Wordt aan gewerkt🚧</p>
          </div>
        ),
      },
      {
        path: '/billing',
        element: (
          <div className="w-full h-full flex justify-center items-center -mt-32">
            <p>🚧Wordt aan gewerkt🚧</p>
          </div>
        ),
      },
      {
        path: '/administrations/:division/data/exact',
        element: <ExactData />,
      },
      {
        path: '/administrations',
        element: <Administrations />,
      },
      {
        path: '/administrations/:divisionId/:tab',
        element: <AdministrationDetails />,
      },
      {
        path: '/oauth/success',
        element: <Spinner />,
      },
      {
        path: '/users',
        element: <UserPage />,
      },
      {
        path: '/dashboards/eol-integration',
        element: <IntegrationDashboardExact />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
