import client from "../../utils/axiosClient";

const getUsers = async (division: string, year: number) => {
  const response = await client.get("/api/kia/transactions", {
    params: {
      division,
      year,
    },
  });
  return response.data.data;
};

export default getUsers;
