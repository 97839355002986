import Stack from '@mui/joy/Stack';
import { PaymentTermAutomationTable } from './table';
import DivisionSearch from '../../../../components/divisionSearch';
import { YearSelect } from '../../../../components/yearSelect';
import { AccountSelect } from '../../../../components/accountSelect';

export const PaymentTermAutomation = () => {
  return (
    <Stack direction="column" gap={3}>
      <Stack direction={'row'} gap={3}>
        <AccountSelect />
        <DivisionSearch />
        <YearSelect />
      </Stack>
      <PaymentTermAutomationTable />
    </Stack>
  );
};
