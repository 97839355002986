import { XCircleIcon } from '@heroicons/react/24/solid';
import { Grid, Stack } from '@mui/joy';
import { Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import Spinner from '../../../../components/spinner';
import { usePaymentTermAutomation } from '../../../../hooks/paymentTerms/usePaymentTermAutomation';
import { PaymentTermAutomationForAdministration } from '../../../../services/api/paymentTerms/getJournalAutomation';
import useFilterStore from '../../../../stores/filter';
import { formatter, plainFormatter } from '../../../../translations/numberFormatter';
import { AutomationChip } from './automationChip';
import { TitleSubtitleCard } from './card';
import { JournalAutomationModal } from './modal';

export const PaymentTermAutomationTable = () => {
  const { divisionSearch, year, account } = useFilterStore();
  const paymentTermAutomationQuery = usePaymentTermAutomation({ year, accountID: account });
  const [selectedAdministration, setSelectedAdministration] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const columns: GridColDef[] = [
    {
      field: 'administrationName',
      headerName: 'Administratie',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'administrationHid',
      headerName: 'Hid',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'custom_01',
      headerName: 'Automatisering',
      flex: 0.25,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <AutomationChip percentage={params.value} />;
      },
      valueGetter: (params) => {
        if (params.row.total === 0) return 100;
        return Math.round(((params.row.scanHerken + params.row.automatic) / params.row.total) * 100);
      },
    },
    {
      field: 'import',
      headerName: 'Import',
      flex: 0.5,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        return params.value.toLocaleString('nl-NL');
      },
    },
    {
      field: 'manualEntry',
      headerName: 'Handmatig',
      flex: 0.5,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        return params.value.toLocaleString('nl-NL');
      },
    },
    {
      field: 'semiAutomatic',
      headerName: 'Digitaal',
      flex: 0.5,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        return params.value.toLocaleString('nl-NL');
      },
    },
    {
      field: 'scanHerken',
      headerName: 'Webservice',
      flex: 0.5,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        return params.value.toLocaleString('nl-NL');
      },
    },
    {
      field: 'automatic',
      headerName: 'Automatisch',
      flex: 0.5,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        return params.value.toLocaleString('nl-NL');
      },
    },
    {
      field: 'total',
      headerName: 'Totaal',
      flex: 0.5,
      disableColumnMenu: true,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: (params) => {
        return params.value.toLocaleString('nl-NL');
      },
    },
  ];

  const rows = useMemo(() => {
    if (!paymentTermAutomationQuery.isSuccess) return [] as PaymentTermAutomationForAdministration[];

    // Return all if no filters are applied
    if (!divisionSearch) return paymentTermAutomationQuery.data.administrations;

    // Filter by division
    return paymentTermAutomationQuery.data.administrations.filter((row) => row.administrationName.toLowerCase().includes(divisionSearch.toLowerCase()));
  }, [divisionSearch, paymentTermAutomationQuery.data]);

  if (!paymentTermAutomationQuery.isSuccess) {
    return <Spinner />;
  }

  if (!rows.length) {
    return (
      <Stack alignItems={'center'} direction={'column'} sx={{ my: '8rem' }} gap={1}>
        <XCircleIcon className="w-32 h-32 text-warning text-center" />
        <Typography>Geen data gevonden</Typography>
      </Stack>
    );
  }

  const activeRow = rows.find((row) => {
    return row.administrationId === selectedAdministration;
  });

  const metadata = paymentTermAutomationQuery.data.metadata;

  return (
    <>
      <JournalAutomationModal
        open={modalOpen}
        setOpen={setModalOpen}
        administrationName={activeRow?.administrationName || ''}
        journals={activeRow?.journals || ([] as any)}
      />

      {/* KPIS */}
      <Grid container spacing={3}>
        <Grid xs={12} lg={12 / 5}>
          <TitleSubtitleCard variant="outlined" title="Facturen" subtitle={plainFormatter.format(metadata.totalInvoices).toString()} />
        </Grid>
        <Grid xs={12} lg={12 / 5}>
          <TitleSubtitleCard variant="outlined" title="Handmatig/digitaal" subtitle={plainFormatter.format(metadata.totalManualInvoices).toString()} />
        </Grid>
        <Grid xs={12} lg={12 / 5}>
          <TitleSubtitleCard
            variant="outlined"
            title="Als percentage"
            subtitle={Math.floor((metadata.totalManualInvoices / metadata.totalInvoices) * 100).toString() + '%'}
          />
        </Grid>
        <Grid xs={12} lg={12 / 5}>
          <TitleSubtitleCard
            variant="outlined"
            title="Te besparen uren"
            subtitle={plainFormatter.format(Math.round((metadata.totalManualInvoices * 3) / 60)) + ' uren'}
          />
        </Grid>
        <Grid xs={12} lg={12 / 5}>
          <TitleSubtitleCard
            variant="outlined"
            title="Geld te besparen"
            subtitle={formatter.format(Math.round(((metadata.totalManualInvoices * 3) / 60) * 50))}
          />
        </Grid>
      </Grid>

      {/* Table */}
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
          sorting: {
            sortModel: [{ field: 'manualEntry', sort: 'desc' }],
          },
        }}
        getRowId={(row) => row.administrationId}
        onRowClick={(params) => {
          setSelectedAdministration(params.row.administrationId);
          setModalOpen(true);
        }}
      />
    </>
  );
};
