import InfoIcon from '@mui/icons-material/Info';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Alert, Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Stack from '@mui/joy/Stack';
import { useEffect, useState } from 'react';
import DivisionSearch from '../../components/divisionSearch';
import { PageHeader } from '../../components/pageHeader';
import { RangeSelect } from '../../components/rangeSelect';
import { UserSelect } from '../../components/userSelect';
import { useInsightBalanceOverview } from '../../hooks/insights/useInsightOverview';
import { useMe } from '../../hooks/useMe';
import useFilterStore from '../../stores/filter';
import { InsightOverviewBalanceTable } from './balanceTable';
import { InsightOverviewKpiTable } from './kpiTable';
import Modal from './modal';

const Filters = ({ isAdmin, setModalOpen }: { setModalOpen: (value: boolean) => void; isAdmin: boolean }) => {
  return (
    <Stack direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between' }} gap={3}>
      <Stack direction={'row'} gap={3}>
        <UserSelect />
        <DivisionSearch />
        <RangeSelect />
      </Stack>

      <FormControl>
        <FormLabel></FormLabel>
        <Button
          endDecorator={<SettingsRoundedIcon />}
          onClick={() => {
            setModalOpen(true);
          }}
          sx={{ marginTop: '1rem' }}
          disabled={!isAdmin}
        >
          Checks
        </Button>
      </FormControl>
    </Stack>
  );
};

export default function InsightOverview() {
  const { range, divisionSearch, user } = useFilterStore();
  const [modalOpen, setModalOpen] = useState(false);
  const overviewQuery = useInsightBalanceOverview({
    yearStart: range.yearStart,
    yearEnd: range.yearEnd,
    monthStart: range.monthStart + 1,
    monthEnd: range.monthEnd + 1,
  });
  const userQuery = useMe();

  const refetch = async () => {
    await overviewQuery.refetch();
  };

  const isAdmin = userQuery.data?.Role === 'super' || userQuery.data?.Role === 'admin';

  useEffect(() => {
    refetch();
  }, [modalOpen]);

  return (
    <Stack direction="column" gap={3}>
      <PageHeader>Inzicht</PageHeader>
      <Alert variant="soft" color="primary" startDecorator={<InfoIcon />}>
        Dit dashboard is in ontwikkeling.
      </Alert>

      <Tabs defaultValue={'balance'}>
        <TabList>
          <Tab variant="plain" value={'balance'} color="neutral">
            Balans checks
          </Tab>
          <Tab variant="plain" value={'kpi'} color="neutral" disabled>
            Kengetal checks
          </Tab>
        </TabList>

        <TabPanel value={'balance'}>
          <Stack gap={3}>
            <Filters isAdmin={isAdmin} setModalOpen={setModalOpen} />
            <InsightOverviewBalanceTable
              yearStart={range.yearStart}
              yearEnd={range.yearEnd}
              monthStart={range.monthStart}
              monthEnd={range.monthEnd}
              divisionSearch={divisionSearch}
              userID={user}
            />
          </Stack>
        </TabPanel>
        <TabPanel value={'kpi'}>
          <InsightOverviewKpiTable
            yearStart={range.yearStart}
            yearEnd={range.yearEnd}
            monthStart={range.monthStart}
            monthEnd={range.monthEnd}
            divisionSearch={divisionSearch}
            userId={user}
          />
        </TabPanel>
      </Tabs>

      <Modal isOpen={modalOpen} setOpen={setModalOpen} />
    </Stack>
  );
}
