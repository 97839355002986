import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import { useUsers } from "../hooks/useUsers";
import useFilterStore from "../stores/filter";

export const UserSelect = () => {
  const { user, setUser } = useFilterStore();
  const usersQuery = useUsers();

  const users = usersQuery.data || [];

  return (
    <FormControl>
      <FormLabel>Gebruiker</FormLabel>
      <Select
        placeholder={"Selecteer een gebruiker"}
        sx={{ minWidth: "12rem" }}
        onChange={(_, newValue) => {
          const userId = users.find((u) => u.id === newValue)?.id || "";
          setUser(userId);
        }}
        value={users.find((u) => u.id === user)?.id || ""}
      >
        <Option value="">
          <em>Geen filter</em>
        </Option>
        {users.map((user) => {
          return (
            <Option key={user.id} value={user.id}>
              {user.email.split("@")[0].replace(".", " ")}
            </Option>
          );
        })}
      </Select>
    </FormControl>
  );
};
