import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Card, CardContent, IconButton, Stack, Textarea } from "@mui/joy";
import { Typography } from "@mui/material";
import { useState } from "react";
import { ConfirmationDialog } from "../../components/confirmationDialog";
import { TNote } from "../../services/api/notes/getNotes";
import { deleteNote } from "../../services/api/notes/deleteNote";

export const Note = ({
  note,
  refetch,
}: {
  note: TNote;
  refetch: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const date = new Date(Number.parseInt(note.createdAt, 10))
    .toLocaleString("nl-NL")
    .slice(0, 9);
  return (
    <>
      <ConfirmationDialog
        content="Weet je zeker dat je deze notitie wilt verijderen?"
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={async () => {
          await deleteNote({
            noteId: note.id,
          });
          await refetch();
        }}
        open={open}
        title="Notitie verwijderen"
      />
      <Card variant="outlined">
        <CardContent>
          <Stack direction={"row"} justifyContent={"space-between"} gap={1}>
            <Typography>{`${date} (${note.rgsCode})`}</Typography>
            <IconButton
              onClick={() => setOpen(true)}
              variant="plain"
              sx={{
                padding: 0,
                margin: 0,
                marginTop: "-1rem",
                marginRight: "-1rem",
                // marginLeft: "1rem",
              }}
            >
              <CloseRoundedIcon sx={{ width: "1rem" }} />
            </IconButton>
          </Stack>
          <Textarea
            variant="plain"
            disabled
            value={note.text}
            minRows={2}
            maxRows={2}
          />
        </CardContent>
      </Card>
    </>
  );
};
