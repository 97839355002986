export const webhookDummyData = [
  {
    EventDate: '2024-08-19',
    divisionWebhooks: 102,
  },
  {
    EventDate: '2024-08-20',
    divisionWebhooks: 431,
  },
  {
    EventDate: '2024-08-21',
    divisionWebhooks: 396,
  },
  {
    EventDate: '2024-08-22',
    divisionWebhooks: 387,
  },
  {
    EventDate: '2024-08-23',
    divisionWebhooks: 384,
  },
  {
    EventDate: '2024-08-24',
    divisionWebhooks: 334,
  },
  {
    EventDate: '2024-08-25',
    divisionWebhooks: 192,
  },
  {
    EventDate: '2024-08-26',
    divisionWebhooks: 322,
  },
  {
    EventDate: '2024-08-27',
    divisionWebhooks: 483,
  },
  {
    EventDate: '2024-08-28',
    divisionWebhooks: 465,
  },
  {
    EventDate: '2024-08-29',
    divisionWebhooks: 399,
  },
  {
    EventDate: '2024-08-30',
    divisionWebhooks: 394,
  },
  {
    EventDate: '2024-08-31',
    divisionWebhooks: 349,
  },
  {
    EventDate: '2024-09-01',
    divisionWebhooks: 215,
  },
  {
    EventDate: '2024-09-02',
    divisionWebhooks: 321,
  },
  {
    EventDate: '2024-09-03',
    divisionWebhooks: 587,
  },
  {
    EventDate: '2024-09-04',
    divisionWebhooks: 392,
  },
  {
    EventDate: '2024-09-05',
    divisionWebhooks: 312,
  },
];
