import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import { Pagination } from "@mui/material";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/dynamicSpinner";
import NoData from "../../components/noData";
import { SortDirection, ThSort } from "../../components/tables/thSort";
import { useInsightBalanceOverview } from "../../hooks/insights/useInsightOverview";
import { balanceCheckIconOptions } from "./modal/balanceCheckSubForm";
import { YearIcon } from "../insights-drilldown/balanceInsightTable";

const PAGE_SIZE = 13;

type Props = {
  yearStart: number;
  yearEnd: number;
  monthStart: number;
  monthEnd: number;
  divisionSearch: string;
  userId?: string;
};

enum SortOptions {
  name = "name",
}

export const InsightOverviewKpiTable = (props: Props) => {
  const { divisionSearch, userId, yearStart, yearEnd, monthStart, monthEnd } =
    props;
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<SortOptions>(SortOptions.name);
  const [sortDir, setSortDir] = useState<SortDirection>(SortDirection.asc);
  const navigate = useNavigate();
  const overviewQuery = useInsightBalanceOverview({
    yearStart,
    yearEnd,
    monthStart: monthStart + 1,
    monthEnd: monthEnd + 1,
  });

  useEffect(() => {
    setPage(0);
  }, [userId]);

  useEffect(() => {
    setPage(0);
  }, [divisionSearch]);

  const onRowClick = (id: string) => {
    navigate({
      pathname: `/administrations/${id}/insights`,
    });
  };

  const rows = useMemo(() => {
    if (!overviewQuery.isSuccess || !overviewQuery.data?.rows.length) {
      return [];
    }

    const filtered = overviewQuery.data.rows.filter((item) => {
      let show = true;

      if (
        !item.administrationName
          ?.toLowerCase()
          .includes(divisionSearch.toLowerCase())
      ) {
        show = false;
      }
      return show;
    });

    const sorted = filtered.sort((item1, item2) => {
      if (sort === SortOptions.name) {
        if (sortDir === SortDirection.asc) {
          return item1.administrationName.localeCompare(
            item2.administrationName
          );
        }
        if (sortDir === SortDirection.desc) {
          return item2.administrationName.localeCompare(
            item1.administrationName
          );
        }
      }

      return 0;
    });

    return sorted;
  }, [
    overviewQuery.data,
    overviewQuery.isSuccess,
    divisionSearch,
    sort,
    sortDir,
  ]);

  if (overviewQuery.isLoading) {
    return <Spinner />;
  }

  if (!overviewQuery.data?.rows.length) {
    return <NoData />;
  }

  if (!overviewQuery.isSuccess) {
    return <NoData label="Er ging iets mis met de data ophalen" />;
  }

  return (
    <>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
          maxHeight: "80vh",
        }}
      >
        <Table
          aria-label="basic table"
          stickyHeader
          stickyFooter
          hoverRow
          sx={{
            "--TableCell-height": "3rem",
            "& tr": {
              "&:hover": {
                cursor: "pointer",
              },
            },
          }}
        >
          <thead>
            <tr>
              <ThSort
                style={{ width: "20%" }}
                label="Administratie"
                setSort={setSort}
                sort={sort}
                sortDir={sortDir}
                setSortDir={setSortDir}
                sortKey={SortOptions.name}
              />
              {overviewQuery.data.range.map((yearMonth) => {
                // Get month label in dutch
                const month = new Date(
                  yearMonth.year,
                  yearMonth.month - 1
                ).toLocaleString("nl-NL", { month: "long" });
                return (
                  <th
                    style={{
                      width: `${70 / overviewQuery.data.range.length}%`,
                    }}
                  >
                    {month}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((row) => {
              const now = new Date();
              return (
                <tr
                  key={row.administrationId}
                  onClick={() => {
                    onRowClick(row.administrationId);
                  }}
                >
                  <td>{row.administrationName}</td>
                  {overviewQuery.data.range.map((yearMonth) => {
                    const triggered =
                      row.triggers[yearMonth.year][yearMonth.month];

                    // Disabled if in the future
                    now.setFullYear(yearMonth.year);
                    now.setMonth(yearMonth.month - 1);

                    const disabled = now > new Date();
                    if (!triggered) {
                      let color = "text-green-500";
                      if (disabled) color = "text-neutral";
                      return (
                        <td>
                          <CheckCircleRoundedIcon
                            className={clsx("w-5", color)}
                          />
                        </td>
                      );
                    }

                    let color = "text-yellow-500";
                    if (disabled) color = "text-neutral";
                    return (
                      <td>
                        <ErrorRoundedIcon className={clsx("w-5", color)} />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(overviewQuery.data.rows.length / PAGE_SIZE)}
          color="primary"
          onChange={(_, page) => setPage(page - 1)}
          page={page + 1}
        />
      </Box>
    </>
  );
};
