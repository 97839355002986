import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import AddIcon from '@mui/icons-material/Add';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import { Badge, IconButton, Modal, ModalDialog, Stack, Tab, TabList, TabPanel, Tabs, Typography } from '@mui/joy';
import ModalClose from '@mui/joy/ModalClose';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { useEffect, useState } from 'react';
import { useNotes } from '../../hooks/notes/useNotes';
import useDivision from '../../hooks/useDivision';
import { NoteForm } from './noteForm';
import { Notes } from './notes';
import TransactionTable from './table';
type Props = {
  division: string;
  year: number;
  month: number;
  checkId: string;
  rgsCode: string;
  setOpen: (open: boolean) => void;
  open: boolean;
};

const months = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];

export const TransactionModal = (props: Props) => {
  const { setOpen, checkId, division, month: monthProp, year, open, rgsCode } = props;
  const [monthIndex, setMonthIndex] = useState(monthProp);
  const divisionQuery = useDivision(division);
  const [tab, setTab] = useState<'note' | 'transactions'>('transactions');
  const notesQuery = useNotes({
    administrationIds: [division],
    year,
    period: monthIndex,
  });

  useEffect(() => {
    setMonthIndex(monthProp);
  }, [monthProp]);

  const PeriodNavigator = () => {
    return (
      <Stack direction={'row'} alignItems={'center'} gap={1} marginBottom={'1rem'}>
        <IconButton disabled={monthIndex === 0} onClick={() => setMonthIndex((e) => e - 1)}>
          <ChevronLeftIcon className="w-4 disabled:text-gray-300" />
        </IconButton>
        <Typography>{months[monthIndex]}</Typography>
        <IconButton disabled={monthIndex === 11} onClick={() => setMonthIndex((e) => e + 1)}>
          <ChevronRightIcon className="w-4 disabled:text-gray-300" />
        </IconButton>
      </Stack>
    );
  };

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <ModalDialog minWidth={'60vw'}>
          <ModalClose variant="soft" color="primary" />
          <Typography level="h2">{`${rgsCode} - ${months[monthIndex]} ${year}`}</Typography>
          <Tabs defaultValue={'transactions'} sx={{ marginTop: '1rme' }}>
            <TabList>
              <Tab variant="plain" value={'transactions'} color="neutral">
                Transacties
              </Tab>
              <Tab variant="plain" value={'notes'} color="neutral">
                <Badge badgeContent={notesQuery.data?.length}>
                  Notities
                  <DescriptionRoundedIcon />
                </Badge>
              </Tab>
            </TabList>

            <TabPanel value={'transactions'}>
              <PeriodNavigator />
              <TransactionTable division={division} checkId={checkId} month={monthIndex} year={year} />
            </TabPanel>
            <TabPanel value={'notes'}>
              <PeriodNavigator />
              <Stack direction={'column'} gap={3}>
                <Notes administrationIds={[division]} year={year} period={monthIndex} />

                {tab === 'note' && (
                  <NoteForm
                    administrationId={division}
                    financialPeriod={monthIndex}
                    financialYear={year}
                    rgsCode={rgsCode}
                    goBack={async () => {
                      setTab('transactions');
                      await notesQuery.refetch();
                    }}
                  />
                )}
              </Stack>
            </TabPanel>
          </Tabs>
        </ModalDialog>
      </Modal>
      {tab !== 'note' && open && (
        <Fab
          variant="extended"
          color="primary"
          aria-label="add note"
          onClick={() => setTab('note')}
          sx={{
            position: 'fixed',
            bottom: '1rem',
            right: '1rem',
            zIndex: 10000000,
          }}
        >
          <AddIcon />
          Notitie
        </Fab>
      )}
    </>
  );
};
