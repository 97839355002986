import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import { Pagination } from "@mui/material";
import clsx from "clsx";
import { useMemo, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import NoData from "../../components/noData";
import Spinner from "../../components/spinner";
import { SortDirection, ThSort } from "../../components/tables/thSort";
import { useInvalidVatDashboard } from "../../hooks/transactions/useInvalidVatDashboard";
import { formatter } from "../../translations/numberFormatter";

const PAGE_SIZE = 13;

type Props = {
  yearStart: number;
  yearEnd: number;
  monthStart: number;
  monthEnd: number;
  divisionSearch: string;
  userId?: string;
};

enum SortOptions {
  name = "name",
  totalAmount = "totalAmount",
}

export const InvalidVatTable = (props: Props) => {
  const { yearStart, yearEnd, monthEnd, monthStart, divisionSearch, userId } =
    props;
  const [sort, setSort] = useState<SortOptions>(SortOptions.totalAmount);
  const [sortDir, setSortDir] = useState<SortDirection>(SortDirection.desc);
  const invalidVatDashboardQuery = useInvalidVatDashboard({
    yearStart,
    yearEnd,
    monthStart,
    monthEnd,
  });
  const [page, setPage] = useState(0);

  const months: Array<number> = [];
  for (let index = monthStart; index <= monthEnd; index += 1) {
    months.push(index);
  }

  const navigate = useNavigate();
  const onRowClick = (id: string) => {
    navigate({
      pathname: `/dashboards/vat/${id}`,
    });
  };

  const rows = useMemo(() => {
    if (!invalidVatDashboardQuery.data) {
      return [];
    }

    const filteredRows = invalidVatDashboardQuery.data.rows.filter((row) => {
      let show = true;

      if (
        !row.administrationName
          ?.toLowerCase()
          .includes(divisionSearch.toLowerCase())
      ) {
        show = false;
      }

      return show;
    });

    const orderedRows = filteredRows.sort((item1, item2) => {
      if (sort === SortOptions.totalAmount) {
        if (sortDir === SortDirection.asc) {
          return item1.totalAmount - item2.totalAmount;
        }
        if (sortDir === SortDirection.desc) {
          return item2.totalAmount - item1.totalAmount;
        }
      }

      if (sort === SortOptions.name) {
        if (sortDir === SortDirection.asc) {
          return item1.administrationName.localeCompare(
            item2.administrationName
          );
        }
        if (sortDir === SortDirection.desc) {
          return item2.administrationName.localeCompare(
            item1.administrationName
          );
        }
      }

      return 0;
    });

    return orderedRows;
  }, [
    invalidVatDashboardQuery.data,
    invalidVatDashboardQuery.isSuccess,
    divisionSearch,
    sort,
    sortDir,
  ]);

  if (!invalidVatDashboardQuery.isSuccess) {
    return <Spinner />;
  }

  if (!rows.length) {
    return <NoData />;
  }

  return (
    <>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
          maxHeight: "80vh",
        }}
      >
        <Table
          aria-label="basic table"
          stickyHeader
          stickyFooter
          hoverRow
          sx={{
            "--TableCell-height": "3rem",
            "& tr": {
              "&:hover": {
                cursor: "pointer",
              },
            },
          }}
        >
          <thead>
            <tr>
              <ThSort
                style={{ width: "20%" }}
                label="Administratie"
                setSort={setSort}
                sort={sort}
                sortDir={sortDir}
                setSortDir={setSortDir}
                sortKey={SortOptions.name}
              />
              <ThSort
                style={{ width: "10%" }}
                label="Totaal btw bedrag"
                setSort={setSort}
                sort={sort}
                sortDir={sortDir}
                setSortDir={setSortDir}
                sortKey={SortOptions.totalAmount}
              />
              {invalidVatDashboardQuery.data.range.map((yearMonth) => {
                // Get month label in dutch
                const month = new Date(
                  yearMonth.year,
                  yearMonth.month
                ).toLocaleString("nl-NL", { month: "long" });
                return (
                  <th
                    style={{
                      width: `${
                        70 / invalidVatDashboardQuery.data.range.length
                      }%`,
                    }}
                  >
                    {month}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rows.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE).map((row) => {
              const now = new Date();
              return (
                <tr
                  key={row.administrationId}
                  onClick={() => {
                    onRowClick(row.administrationId);
                  }}
                >
                  <td>{row.administrationName}</td>
                  <td>{formatter.format(row.totalAmount)}</td>
                  {invalidVatDashboardQuery.data.range.map((yearMonth) => {
                    const periodVatAmount =
                      row.amountsPerPeriod[yearMonth.year][yearMonth.month];

                    if (periodVatAmount === 0) {
                      let color = "text-green-500";
                      return (
                        <td>
                          <CheckCircleRoundedIcon
                            className={clsx("w-5", color)}
                          />
                        </td>
                      );
                    }

                    let color = "text-yellow-500";
                    return (
                      <td>
                        <ErrorRoundedIcon className={clsx("w-5", color)} />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Sheet>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={Math.ceil(rows.length / PAGE_SIZE)}
          color="primary"
          onChange={(_, page) => setPage(page - 1)}
          page={page + 1}
        />
      </Box>
    </>
  );
};
