import { create } from "zustand";
import { Cookies, getCookie, setCookie } from "../services/cookies";
import { getUserDomains } from "../services/proxy/proxy";

type State = {
  domain: string;
  setDomain: (domain: string) => void;
  init: () => Promise<void>;
  isInitialized: boolean;
};

const domainStore = create<State>((set) => {
  return {
    domain: "",
    isInitialized: false,
    setDomain: (domain) => {
      set(() => ({ domain }));
      setCookie(Cookies.DOMAIN, domain);
    },
    init: async () => {
      const attemptLoad = async () => {
        let savedDomain = getCookie(Cookies.DOMAIN);
        const domains = await getUserDomains();

        // Returning user, use saved domain
        const userStillHasAccess = domains.find(
          (domain) => domain.domainId === savedDomain
        );
        if (savedDomain && userStillHasAccess) {
          set(() => ({ domain: savedDomain!, isInitialized: true }));
          return;
        }

        const newDomain = domains[0]?.domainId;

        // New user without any domain(s)
        if (!domains.length || !newDomain) {
          console.info("User not added to any domains");
          return;
        }

        // New user *with* domain(s) OR returning user with revoked access to saved domain
        set(() => ({ domain: newDomain!, isInitialized: true }));
        setCookie(Cookies.DOMAIN, newDomain!);
      };

      let tries = 0;
      let maxTries = 3;
      let delay = 500;
      let interval = setInterval(async () => {
        try {
          await attemptLoad();
        } catch (error) {
          console.error(`Error loading domain: ${error}`);
        } finally {
          if (tries++ >= maxTries) {
            clearInterval(interval);
          }
        }
      }, delay);
    },
  };
});

export default domainStore;
