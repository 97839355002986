import { VariantProp } from '@mui/joy';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';

export const TitleSubtitleCard = (props: { variant: VariantProp; title: string; subtitle: string }) => {
  return (
    <Card variant={props.variant}>
      <CardContent>
        <Typography level="title-md">{props.title}</Typography>
        <Typography>{props.subtitle}</Typography>
      </CardContent>
    </Card>
  );
};
