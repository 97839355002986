import client from "../../../utils/axiosClient";

export type TGetInvalidVatDashboarParams = {
  yearStart: number;
  yearEnd: number;
  monthStart: number;
  monthEnd: number;
};

export type TIllegalVatDashboardResponse = {
  range: Array<{ year: number; month: number }>;
  rows: {
    administrationId: string;
    administrationName: string;
    totalAmount: number;
    amountsPerPeriod: {
      [year: number]: { [period: number]: number };
    };
  }[];
};

export const getInvalidVatDashboard = async (
  config: TGetInvalidVatDashboarParams
) => {
  const response = await client.get<TIllegalVatDashboardResponse>(
    `/api/transactions/vat/invalid-vat-dashboard`,
    {
      params: config,
    }
  );
  return response.data;
};
