import { create } from 'zustand';
import { Option } from '../components/dropdown';
import { Range } from '../components/rangeSelect';
import dayjs from '../utils/dayjs';

type FilterState = {
  user: string;
  setUser: (user: string) => void;
  year: number;
  setYear: (year: number) => void;
  period: number;
  setPeriod: (year: number) => void;
  options: Array<Option>;
  monthOptions: Array<Option>;
  range: Range;
  setRange: (range: Range) => void;
  selectedDivision: string | null;
  setSelectedDivision: (division: string | null) => void;
  divisionSearch: string;
  setDivisionSearch: (division: string) => void;
  enableRangeSelect: boolean;
  setEnableRangeSelect: (enable: boolean) => void;
  account: string | null;
  setAccount: (account: string | null) => void;
  reset: () => void;
};

const options: Array<Option> = [
  { key: 2020, label: 2020 },
  { key: 2021, label: 2021 },
  { key: 2022, label: 2022 },
  { key: 2023, label: 2023 },
  { key: 2024, label: 2024 },
];

const monthOptions: Array<Option> = Array.from(Array(12).keys()).map((e) => {
  return {
    key: e,
    label: dayjs(new Date().setMonth(e)).format('MMM'),
  };
});

const defaultRange: Range = {
  monthStart: monthOptions[0].key as number,
  monthEnd: monthOptions[monthOptions.length - 1].key as number,
  yearStart: new Date().getFullYear(),
  yearEnd: new Date().getFullYear(),
};

const filterStore = create<FilterState>((set) => ({
  user: 'none',
  setUser: (user) => set(() => ({ user })),
  year: 2024,
  setYear: (year) => set(() => ({ year })),
  account: null,
  setAccount: (account) => set(() => ({ account })),
  period: 1,
  setPeriod: (period) => set(() => ({ period })),
  options,
  monthOptions,
  range: defaultRange,
  setRange: (range: Range) => set(() => ({ range })),
  selectedDivision: null,
  divisionSearch: '',
  setDivisionSearch: (term: string) => set(() => ({ divisionSearch: term })),
  setSelectedDivision: (division: string | null) => set(() => ({ selectedDivision: division })),
  enableRangeSelect: false,
  setEnableRangeSelect: (enable: boolean) =>
    set(() => ({
      enableRangeSelect: enable,
      yearStart: 2024,
      yearEnd: 2024,
      monthStart: 1,
      monthEnd: 12,
    })),
  reset: () => {
    set(() => {
      return {
        user: '',
        year: 2024,
        period: 1,
        range: defaultRange,
        selectedDivision: null,
        divisionSearch: '',
      };
    });
  },
}));

export default filterStore;
